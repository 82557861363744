#ingredient-form {
  padding: 1em;
  background: linear-gradient(22deg, #d1dbff, #f9dcea);

  small {
    font-size: 0.9em;
  }
}

.ifa-response {
  background-color: $color-yellow;
  padding: 0.4em 0.3em;
  font-weight: bold;
}
