body {
  @include regularText;
}

h1, h2, h3, h4, h5, h6 {
  @include headingText;
  text-transform: uppercase;
}

h1 {
  font-size: 3.5rem;
}

b, strong {
  font-weight: bold;
}

.large {
  font-size: 1.5rem;
}

.panel {
  background: linear-gradient(22deg, #d4dcf7, #ffdde9);
  padding: 1em;
}

.quote {
  border-left: 7px solid white;
  padding: 0.5em 0 0.5em 1em;
}

.text-red {
  color: #e3166a;
}
