$color-black-real: #000;
$color-white-real: #fff;

$color-black: #666;
$color-white: $color-white-real;

$color-yellow: #ffef7c;
$color-blue-light: #d4dcf7;

@mixin neg {
  -webkit-font-smoothing: antialiased;
}
