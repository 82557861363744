.footer-legal {
  background-color: $color-black-real;
  color: $color-white-real;
  padding: 0.5em 0;
  font-size: 0.9em;

  a {
    color: $color-white-real;
    font-weight: bold;
    letter-spacing: 0.06em;
    &:hover {
      color: $color-white-real;
    }
  }
}
