.header {
  padding: 1.6em 0 2.2em;

  .header-bar {
    background: linear-gradient(105deg, #e3166a, #213f9a);

    .navbar {
      background-color: rgba(255, 255, 255, 0.75) !important;
      padding-top: 0.25em;
      padding-bottom: 0.25em;

      .nav-link {
        color: rgba(18, 36, 105, 1);
        font-weight: bold;
        font-size: 1.1rem;
        padding-left: 1em;
        padding-right: 1em;
      }
    }
  }

  h1.logo {
    margin-bottom: 0.5em;
  }
}
