$base-font-size: 16px;
$base-line-height: 1.5;

@mixin regularText {
  @include archivo;
}

@mixin headingText {
  @include tekoSemiBold;
}
