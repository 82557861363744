$font-stack-ubuntu: Ubuntu, "Trebuchet MS", Helvetica, Arial, sans-serif;

$font-stack-barlow-condensed: 'Barlow Condensed', sans-serif;
$font-stack-archivo: 'Archivo', sans-serif;
$font-stack-archivo-narrow: 'Archivo narrow', sans-serif;
$font-stack-teko: 'Teko', sans-serif;

@mixin archivo {
  font-family: $font-stack-archivo;
  font-weight: 400;
  font-style: normal;
}

@mixin archivoBold {
  font-family: $font-stack-archivo;
  font-weight: 700;
  font-style: normal;
}

@mixin tekoSemiBold {
  font-family: $font-stack-teko;
  font-weight: 500;
  font-style: normal;
}
